<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>I have flight reviews and medical renewals and other scheduled currencies - how do I manage this?</h2>
      <p>
        Once you have a medical and/or a licence, it comes with many obligations, including renewal. Sometimes these
        renewals can be <i>years</i> away. It is <b>your</b> responsibility to manage this &mdash; so how to best achieve this?
      </p>
      <p>
        We recommend using a calendar system to enter in your required renewals. For example, Google Calendar is available to
        anyone with a Google account. There is also other calendar software into which you can enter your required currency renewals.
        Let's give some examples.
      </p>
      <p>
        Suppose you are issued a medical on 21 September 2021 and it is due for renewal in exactly one year. Open up your calendar
        and create an event on 22 September 2022 and title it, "Medical renewal." There is one more important part, which is to
        add one or more notifications. Calendar events can have <i>notifications</i> attached to them, such that you will receive
        a reminder at some period of time prior to the event. For medicals, you probably want to be made aware about 5-6 weeks prior,
        so that you can make the doctor appointment, cover any other issues, etc. You'll probably want another notification closer to
        the event. So in summary, we will add two notifications to our medical renewal event; one at 6 weeks prior and another say, 3
        days prior. You can customise your notifications according to your own time and scheduling style.
      </p>
      <p>
        Let's do another example. Anyone who carries passengers in an aircraft is aware of a requirement to have conducted three take-off
        and landing in the previous 90 days <i>CASR1998 61.395(1)</i>. So you've just got home from flying 8 circuits, filling out your logbook,
        de-briefing yourself after your flight. You can also create a calendar event, in 90 days time from today, to track your passenger currency.
        How can you work out 90 days from today? Simply type into a search engine, "what date is 90 days from today?" So you create a
        calendar event on that date called "PAX currency" and attach your preferred notifications. This way, you can always reference
        your passenger currency by viewing your calendar, and you will always be kept aware of your passenger currency by notification.
      </p>
      <p>
        Time management is an important skills of a pilot; especially in a professional environment. Learning to effectively automate
        time-tracking and scheduling is a skill that will serve you well in aviation and many other environments too!
      </p>
      <p>
        All Flightscope Aviation staff use this system for currency. Another feature of an automated calendar system is that of <i>sharing</i>.
        That is, you can modify the settings of your calendar to allow others to view or edit it. When working in a team environment, it
        can be important that each team member is able to view the currencies of others. Once you become proficient with your calendar,
        give it a try by sharing your calendar with one of your friends!
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
</style>
